// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
    50 : #e4e4e4,
    100 : #bcbcbc,
    200 : #8f8f8f,
    300 : #626262,
    400 : #404040,
    500 : #1e1e1e,
    600 : #1a1a1a,
    700 : #161616,
    800 : #121212,
    900 : #0a0a0a,
    A100 : #e07171,
    A200 : #d74747,
    A400 : #eb0000,
    A700 : #d10000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$aFabfas-primary: mat.define-palette($md-mcgpalette0);
$aFabfas-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$aFabfas-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
// $aFabfas-theme: mat.define-light-theme(
//   (
//     color: (
//       primary: $aFabfas-primary,
//       accent: $aFabfas-accent,
//       warn: $aFabfas-warn,
//     ),
//   )
// );
$aFabfas-theme: mat.define-light-theme(
  (
    color: (
      primary: $aFabfas-primary,
      accent: $aFabfas-accent,
      warn: $aFabfas-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($aFabfas-theme);

/* You can add global styles to this file, and also import other style files */

// html,
// body {
//   height: 100%;
// }
// body {
//   margin: 0;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
// }

// html,
// body {
//   // height: 100%; 
// }

// @font-face {
//   font-family: Inter;
//   src: url("http://localhost:3500/fonts/inter/inter.css");
// }

body {
  margin: 0;
  font-family: 'Roboto', "Helvetica Neue", sans-serif;

  // font-family: 'Inter', "Roboto", "Helvetica Neue", sans-serif;
}


// body {
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
// }
// footer {
//   margin-top: auto;
//   // position: sticky;
//   // left: 0;
//   // bottom: 0;
//   // width: 100%;
//   // min-height: 30px;
//   background-color: azure;
//   color: black;
//   text-align: center;
// }
